export const Games = [
    {
        "game": "Rust",
        "gameUrl": "rust"
    },
    {
        "game": "Escape from Tarkov",
        "gameUrl": "tarkov"
    },
    // {
    //     "game": "Rainbow Six Siege",
    //     "gameUrl": "rainbow"
    // },
    // {
    //     "game": "Dayz",
    //     "gameUrl": "dayz"
    // },
    {
        "game": "Spoofer",
        "gameUrl": "spoofer"
    },
    // {
    //     "game": "Counter Strike 2",
    //     "gameUrl": "cs2"
    // },
    {
        "game": "Apex Legends",
        "gameUrl": "apex"
    },
    // {
    //     "game": "Battlebit",
    //     "gameUrl": "battlebit"
    // },
    // {
    //     "game": "Modern Warfare",
    //     "gameUrl": "modernwarfare"
    // },
    // {
    //     "game": "Valorant",
    //     "gameUrl": "valorant"
    // }
    

    

]

