//RUST IMAGES

import rustlastcheat from '../images/rust/rustlastcheat.webp';
import rustdisarray from '../images/rust/rustdisarrayfivestar.webp';
import rustdivision from '../images/rust/rustdivisionfivestar.webp';
import rustdisconnect from '../images/rust/rustdisconnectfivestar.webp';
import rustfluent from '../images/rust/rustfluentfivestar.webp';
import rustquantumpublic from '../images/rust/rustquantumpublicfivestar.webp';
import rustquantumprivate from '../images/rust/rustquantumprivatefivestar.webp';
import rustrustsense from '../images/rust/rustsensefivestar.webp';
import rustaccounts from '../images/rust/fivestarrustaccounts.webp';
import RustMonolith from '../images/rust/Monolith.webp';

//TARKOV IMAGES
import tarkovcoffeelite from '../images/tarkov/tarkovcoffeelitefivestar.webp';
import tarkovcoffeefull from '../images/tarkov/tarkovcoffeefullfivestar.webp';
import tarkovterralabs from '../images/tarkov/tarkovterrafivestar.webp';
import tarkovquantumprivate from '../images/tarkov/tarkovquantumprivatefivestar.webp';

//RAINBOW SIX SIEGE IMAGES
import rainbowklar from '../images/rainbow/rainbowklarfivestar.webp';
import rainbowarmy from '../images/rainbow/rainbowcheatarmyfivestar.webp';

//DAYZ IMAGES
import dayzintel from '../images/dayz/dayzintellesensefivestar.webp';
import KlarDayz from '../images/dayz/Dayzklarfivestar.webp';

//SPOOFER IMAGES
import spooferexception from '../images/spoofer/spooferexception.webp';
import spooferperm from '../images/spoofer/spooferperm.webp';

import permimg from '../images/spoofer/permspooferimg.webp';

//CSGO IMAGES
import csgofecurity from '../images/csgo/csgofecurityfivestar.webp';

//APEX IMAGES
import apexfecurity from '../images/apex/apexfecuirtyfivestar.webp';
import apexcola from '../images/apex/fivestarapexcola.webp';


//BATTLEBIT IMAGES
import battlefecurity from '../images/battlebit/battlebitfecuirtyfivestar.webp';

//MW IMAGES
import mwfecurity from '../images/mw/mwfecuirtyfivestar.webp';
import mwfecurityunlocker from '../images/mw/mwfecuirtyunlockerfivestar.webp';

//VALORANT IMAGES
import valcoffee from '../images/valorant/valorantcoffeefivestar.webp';
import valfecurity from '../images/valorant/valorantcfecuirtyfivestars.webp'; 
import valxorpro from '../images/valorant/fivestarvalorantxorpro.webp';
import valxoralaska from '../images/valorant/fivestarvalorantxoralaska.webp'; 





export const data = [
    {
        name: "Disconnect-External",
        game: "Rust",
        image: rustdisconnect,
        seopath: 'Disconnect-External',
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Rust-External",
        media: [
            {"url": "https://www.youtube.com/embed/ETQYQnYMlJg?si=8DmZIPOyFgiiVCvX"},
        ],
        day: "7",
        week: "30",
        month: "60",
        features: [
            {
                "title": "WEAPON",
                "items": [
                    "Silent Aim",
                    "Aimbot Smoothing",
                    "Aimbot",
                    "Target Team",
                    "Hitchance %",
                    "Draw FOV",
                    "Target Line",
                    "Fire Speed",
                    "Always Automatic",
                    "Insant Eoka",
                    "Instant Bow",
                    "Shotgun Nospread",
                    "Weapon Sliders",
                    "Recoil X",
                    "Recoil Y",
                    "Spread",
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Player ESP",
                    "Show Team",
                    "Show Wounded",
                    "Show Sleeper",
                    "Show Ghost",
                    "Name ESP",
                    "Box (Full, Corner)",
                    "Healthbar (Side, Bottom, Top)",
                    "Distance (Side, Top, Bottom, Name)",
                    "Chams ",
                    "Bounding Box",
                    "Skeleton",
                    "World ESP",
                  ]
                   
            },
            {
                "title": "MISC",
                "items": [
                    "No Fall Damage",
                    "Infinite Jump",
                    "Spiderman",
                    "Flyhack (Toggle, Hold)",
                    "Fakelag (Toggle, Always)",
                    "Suicide ",
                    "Admin Flags",
                    "Zoom",
                    "FOV Changer",
                    "Time Changer",
                    "Bright Night",
                    "Long Neck",
                    "Remove Layer",
                    "Settings",
                    "Shadow Intensity/Size",
                    "Menu Accent Color",
                    "Watermark",
                    "Crosshair",
                    "Disable crosshair while aiming",
                    "Configs",
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "Streamproof"},
        ],
    },

    // spoofer
    {
        name: "Exception",
        game: "Spoofer",
        image: spooferexception,
        seopath: 'Exception-',
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Rust-External",
        media: [
            {"url": "https://www.youtube.com/embed/ETQYQnYMlJg?si=8DmZIPOyFgiiVCvX"},
        ],
        day: "5",
        week: "30",
        month: "60",
        features: [
            {
                "title": "WEAPON",
                "items": [
                    "Silent Aim",
                    "Aimbot Smoothing",
                    "Aimbot",
                    "Target Team",
                    "Hitchance %",
                    "Draw FOV",
                    "Target Line",
                    "Fire Speed",
                    "Always Automatic",
                    "Insant Eoka",
                    "Instant Bow",
                    "Shotgun Nospread",
                    "Weapon Sliders",
                    "Recoil X",
                    "Recoil Y",
                    "Spread",
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Player ESP",
                    "Show Team",
                    "Show Wounded",
                    "Show Sleeper",
                    "Show Ghost",
                    "Name ESP",
                    "Box (Full, Corner)",
                    "Healthbar (Side, Bottom, Top)",
                    "Distance (Side, Top, Bottom, Name)",
                    "Chams ",
                    "Bounding Box",
                    "Skeleton",
                    "World ESP",
                  ]
                  
            },
            {
                "title": "MISC",
                "items": [
                    "No Fall Damage",
                    "Infinite Jump",
                    "Spiderman",
                    "Flyhack (Toggle, Hold)",
                    "Fakelag (Toggle, Always)",
                    "Suicide ",
                    "Admin Flags",
                    "Zoom",
                    "FOV Changer",
                    "Time Changer",
                    "Bright Night",
                    "Long Neck",
                    "Remove Layer",
                    "Settings",
                    "Shadow Intensity/Size",
                    "Menu Accent Color",
                    "Watermark",
                    "Crosshair",
                    "Disable crosshair while aiming",
                    "Configs",
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "Streamproof"},
        ],
    },
    //TARKOV
 
    //Rainbow six

    //Dayz
 
    //CS2
 
    // spoofer

    //APEX
 

    //BATTLE
  

    //MW
 
    //VALORANT
]
